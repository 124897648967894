enum UserProfileNameField {
  CombinedName = 'names.combinedName',
  Username = 'names.username',
  Alias = 'names.alias',
  DisplayName = 'names.displayName',
  ContactName = 'names.contactName',
  PlatformName = 'names.platformName'
}

export default UserProfileNameField;
